<template>
  <h1 class="text-center">Accordions</h1>
</template>

<script>
export default {
  name: "Accordions"
}
</script>

<style scoped>

</style>